exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-academics-js": () => import("./../../../src/pages/academics.js" /* webpackChunkName: "component---src-pages-academics-js" */),
  "component---src-pages-ages-5-to-6-js": () => import("./../../../src/pages/ages5to6.js" /* webpackChunkName: "component---src-pages-ages-5-to-6-js" */),
  "component---src-pages-ages-6-to-9-js": () => import("./../../../src/pages/ages6to9.js" /* webpackChunkName: "component---src-pages-ages-6-to-9-js" */),
  "component---src-pages-ages-9-to-12-js": () => import("./../../../src/pages/ages9to12.js" /* webpackChunkName: "component---src-pages-ages-9-to-12-js" */),
  "component---src-pages-application-js": () => import("./../../../src/pages/application.js" /* webpackChunkName: "component---src-pages-application-js" */),
  "component---src-pages-clubs-js": () => import("./../../../src/pages/clubs.js" /* webpackChunkName: "component---src-pages-clubs-js" */),
  "component---src-pages-consulting-js": () => import("./../../../src/pages/consulting.js" /* webpackChunkName: "component---src-pages-consulting-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-enrichment-js": () => import("./../../../src/pages/enrichment.js" /* webpackChunkName: "component---src-pages-enrichment-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-giving-js": () => import("./../../../src/pages/giving.js" /* webpackChunkName: "component---src-pages-giving-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobapply-js": () => import("./../../../src/pages/jobapply.js" /* webpackChunkName: "component---src-pages-jobapply-js" */),
  "component---src-pages-location-js": () => import("./../../../src/pages/location.js" /* webpackChunkName: "component---src-pages-location-js" */),
  "component---src-pages-organization-js": () => import("./../../../src/pages/organization.js" /* webpackChunkName: "component---src-pages-organization-js" */),
  "component---src-pages-principles-js": () => import("./../../../src/pages/principles.js" /* webpackChunkName: "component---src-pages-principles-js" */),
  "component---src-pages-programs-js": () => import("./../../../src/pages/programs.js" /* webpackChunkName: "component---src-pages-programs-js" */),
  "component---src-pages-religionculture-js": () => import("./../../../src/pages/religionculture.js" /* webpackChunkName: "component---src-pages-religionculture-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

